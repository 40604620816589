<template>
  <div v-if="role">

    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Thông tin gói cước
        </b-card-title>
      </b-card-header>
      <hr class="mt-0">
      <b-card-body>

        <dl class="row" v-if="!role.limitByTransaction">
          <dt class="col-sm-3">
            Ngày hết hạn:
          </dt>
          <dd class="col-sm-9">
            {{ parseDateToString(role.expiredIn) }}

          </dd>
        </dl>

       
        <dl
          v-if="role.limitByTransaction"
          class="row"
        >
          <dt class="col-sm-3">
            Số phiếu thu còn lại
          </dt>
          <dd class="col-sm-9">
            {{ Number(role.tingeeTransactionCount).toLocaleString() }}
          </dd>
        </dl>

        <dl
          v-if="role.limitByTransaction"
          class="row"
        >
          <dt class="col-sm-3">
            Số tin nhắn Zalo OA chủ động
          </dt>
          <dd class="col-sm-9">
            {{ Number(role.zaloOaMessageCount).toLocaleString() }}
          </dd>
        </dl>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardTitle, BCardHeader, BCardBody,
} from 'bootstrap-vue';
import { getUserRole, parseDateToString } from '@/auth/utils';

export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
  },

  setup() {
    const role = getUserRole();

    return { role, parseDateToString };
  },
};
</script>
